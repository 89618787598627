import FormikFileUpload from '@monorepo/common/component/form/FormikFileUpload';
import { Form, Formik } from 'formik';
import React from 'react';
import { Col, FormGroup } from 'reactstrap';
import * as Yup from 'yup';

// 2 MB
const FILE_SIZE = 2 * 1024 * 1024;

const SUPPORTED_FORMATS = ['application/zip', 'text/xml'];

export function createInitialValues() {
  return {
    file: ''
  };
}

export const validationSchema = Yup.object({
  file: Yup.mixed()
    .required('Soubor je povinný')
    .test('fileSize', 'Soubor je příliš veliký', value => value && value.size <= FILE_SIZE)
    .test(
      'fileFormat',
      'Nepodporovaný formát (*.zip / *.xml)',
      value => value && SUPPORTED_FORMATS.includes(value.type)
    )
});

interface EditFormData {
  file: string;
}

interface CsobCrossCheckReportUploadFormProps {
  onSubmit: (data: EditFormData) => Promise<unknown>;
}

export default function CsobCrossCheckReportUploadForm({ onSubmit }: CsobCrossCheckReportUploadFormProps) {
  return (
    <Formik
      initialValues={createInitialValues()}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        onSubmit(values).finally(() => {
          actions.setSubmitting(false);
        });
      }}
    >
      {formik => (
        <Form>
          <FormGroup row>
            <Col>
              <FormikFileUpload
                name="file"
                // https://github.com/formium/formik/issues/1218#issuecomment-481707848
                onChange={() => setTimeout(() => formik.submitForm(), 0)}
              />
            </Col>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
}
